import React from 'react'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  //   Legend,
} from 'chart.js'
import { Line, Bar } from 'react-chartjs-2'

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  //   Legend,
)

export function LineChart({ raw_data, chart_options }) {
  const dateRegex = /^\d{4}-\d{2}-\d{2}$/

  let dateRangeBool = false

  const labels = raw_data?.map(item => {
    if (dateRegex.test(item.x_axis)) {
      const date = new Date(item.x_axis)
      dateRangeBool = true
      if (!isNaN(date) && date instanceof Date) {
        return date.toLocaleString(undefined, {
          month: 'numeric',
          day: 'numeric',
        })
      }
    }

    return item.x_axis
  })

  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: true,
        text: chart_options.title,
      },
    },

    // Modify the axis by adding scales
    scales: {
      // to remove the labels
      x: {
        ticks: {
          display: true,
        },

        // to remove the x-axis grid
        grid: {
          drawBorder: false,
          display: false,
        },
      },
      // to remove the y-axis labels
      y: {
        ticks: {
          display: true,
          beginAtZero: true,
        },
        // to remove the y-axis grid
        grid: {
          drawBorder: true,
          display: true,
        },
      },
    },
  }

  const chart_colors = [
    {
      borderColor: 'rgb(255, 99, 132)',
      backgroundColor: 'rgba(255, 99, 132, 0.5)',
    },
    {
      borderColor: 'rgb(54, 162, 235)',
      backgroundColor: 'rgba(54, 162, 235, 0.5)',
    },
    {
      borderColor: 'rgb(75, 192, 192)',
      backgroundColor: 'rgba(75, 192, 192, 0.5)',
    },
    {
      borderColor: 'rgb(255, 205, 86)',
      backgroundColor: 'rgba(255, 205, 86, 0.5)',
    },
    {
      borderColor: 'rgb(153, 102, 255)',
      backgroundColor: 'rgba(153, 102, 255, 0.5)',
    },
  ]

  const datasets = [
    {
      label: chart_options.labels[0], // Assuming only one label for single raw_data
      data: raw_data.map(item => item.y_axis), // Use raw_data directly
      borderColor: chart_colors[0].borderColor,
      backgroundColor: chart_colors[0].backgroundColor,
    },
  ]

  const data = {
    labels,
    datasets: datasets,
  }

  if (dateRangeBool) {
    return <Line options={options} data={data} />
  } else {
    return <Bar options={options} data={data} />
  }
}
