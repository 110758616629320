import axios from 'axios'
import { compile } from 'path-to-regexp'
import React from 'react'
import { useLocation } from 'react-router-dom'
import Loading from '@/stat/components/Loading'

export const API_URL = process.env.REACT_APP_API_URL

export const ApiContext = React.createContext({})

/**
 * Build a url path with params
 *
 * Example:
 *    urls.organization({id: '1'}) -> "/v1/organization/1/"
 *
 * @param {string} path - the path template
 * @returns {function} a function to feed url params to and compile a URL
 */
const url = path => params => compile(path)(params)

const urls = {
  currentUser: url('/v1/users/current'),
  studentPageViews: url('/v1/caliper/student/views'),
  totalLaunches: url('/v1/caliper/student/launches'),
  recentActivity: url('/v1/caliper/student/recent'),
  fetchAssessmentData: url('/v1/caliper/assessmentcount'),
  fetchGradeData: url('/v1/caliper/averagegrade'),
  fetchAssessmentQuestionData: url('/v1/caliper/questions'),
  fetchCourseList: url('/v1/caliper/course/list'),
  fetchAssessmentList: url('/v1/caliper/assessment/list'),
  token: url('/v1/lti13/token'),
}

/**
 * Map all apis to the specified client
 *
 * @param {*} client - the axios client object to use
 * @returns {Object} apis
 */
const getApis = client => ({
  /**
   * Retrieve the page views for a student
   *
   * @returns {Object} user - the current user from our API
   */
  fetchStudentPageViews: async () => {
    const resp = await client.get(urls.studentPageViews())
    return resp.data
  },
  fetchStudentTotalLaunches: async () => {
    const resp = await client.get(urls.totalLaunches())
    return resp.data
  },
  fetchRecentActivity: async () => {
    const resp = await client.get(urls.recentActivity())
    return resp.data
  },
  fetchAssessmentData: async params => {
    const resp = await client.get(urls.fetchAssessmentData(), {
      params: params,
    })
    return resp.data
  },
  fetchGradeData: async params => {
    const resp = await client.get(urls.fetchGradeData(), {
      params: params,
    })
    return resp.data
  },
  fetchAssessmentQuestionData: async params => {
    const resp = await client.get(urls.fetchAssessmentQuestionData(), {
      params: params,
    })
    return resp.data
  },
  fetchCourseList: async () => {
    const resp = await client.get(urls.fetchCourseList())
    return resp.data
  },
  fetchAssessmentList: async params => {
    const resp = await client.get(urls.fetchAssessmentList(), {
      params: params,
    })
    return resp.data
  },
})

/**
 * ApiProvider gives child components access to a single client bound to each of
 * our API endpoints and automagically manages credentials for authenticating
 * requests.
 */
export default function ApiProvider({ children }) {
  const location = useLocation()
  const client = axios.create({
    baseURL: API_URL,
  })
  const [token, setToken] = React.useState(() => {
    if (sessionStorage.getItem('token')) {
      return sessionStorage.getItem('token')
    } else if (localStorage.getItem('token')) {
      return localStorage.getItem('token')
    }

    return null
  })

  const [expireTime, setExpireTime] = React.useState(() => {
    if (sessionStorage.getItem('expireTime')) {
      return sessionStorage.getItem('expireTime')
    } else if (localStorage.getItem('expireTime')) {
      return localStorage.getItem('expireTime')
    }

    return null
  })

  React.useEffect(() => {
    async function fetchToken() {
      if (
        !token ||
        !expireTime ||
        Math.floor(new Date().getTime() / 1000) > expireTime
      ) {
        const searchParams = new URLSearchParams(location.search)
        const stateToken = searchParams.get('state_token')
        if (stateToken) {
          await client
            .post(urls.token(), {
              state: stateToken,
            })
            .then(resp => {
              const tempToken = resp.headers['x-storehouse-id']
              const tempExpireTime = resp.headers['x-storehouse-expiration']

              sessionStorage.setItem('expireTime', tempExpireTime)
              setExpireTime(tempExpireTime)

              sessionStorage.setItem('token', tempToken)
              setToken(tempToken)
            })
        }
      }
    }

    fetchToken()
  }, [location.search, token])

  if (
    !token ||
    !expireTime ||
    Math.floor(new Date().getTime() / 1000) > expireTime
  ) {
    return <Loading />
  }

  client.interceptors.request.use(
    async config => {
      // Automatically include the auth token when communicating with the
      // API.
      if (config.baseURL === API_URL && !config.headers.Authorization) {
        // set auth header with token

        if (token) {
          config.headers.Authorization = `Bearer ${token}`
        }
      }
      return config
    },
    error => Promise.reject(error),
  )

  return (
    <ApiContext.Provider value={getApis(client)}>
      {children}
    </ApiContext.Provider>
  )
}
