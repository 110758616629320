import { useEffect, useState } from 'react'
import { Auth0Client } from '@auth0/auth0-spa-js'

export const useAuth0Client = () => {
  const [client, setClient] = useState(null)

  useEffect(() => {
    const auth0Client = new Auth0Client({
      domain: process.env.REACT_APP_AUTH0_DOMAIN,
      client_id: process.env.REACT_APP_AUTH0_CLIENT_ID,
      redirect_uri: window.location.origin,
    })
    setClient(auth0Client)
  }, [])

  return client
}
