window.Storehouse = window.Storehouse || {}
window.Storehouse._next_int_value = 0

/**
 * Returns whether the difference between two numbers
 * is within a decimal precision:
 */
export function almostEqual(num1, num2, precision = 0) {
  const diff = Math.abs(num1 - num2)
  return roundTo(diff, precision) === 0
}

/**
 * Rounds a number to a precision:
 *  0   whole numbers, 3
 *  1   tenths, 3.1
 *  2   hundreths, 3.14
 *  etc.
 * Uses EPISLON to ensure mathematical correctness. See:
 * https://stackoverflow.com/questions/11832914/how-to-round-to-at-most-2-decimal-places-if-necessary
 */
export function roundTo(num, precision = 0) {
  const mult = 10 ** precision
  return Math.round((num + Number.EPSILON) * mult) / mult
}

/**
 * Gets a random int such that: min <= n <= max
 */
export function randomInt(min, max) {
  min = Math.ceil(min)
  max = Math.floor(max)
  return Math.floor(Math.random() * (max - min)) + min //The maximum is exclusive and the minimum is inclusive
}

/**
 * Returns the next sequential int, which is globally unique across the entire window.
 * One use of this int is in creating unique element ids, even when multiple webpack
 * bundles are in the window.
 */
export function nextInt() {
  const val = ++window.Storehouse._next_int_value
  if (val === Number.MAX_SAFE_INTEGER) {
    window.Storehouse._next_int_value = 0
  }
  return val
}
