import React from 'react'
import { useApi } from '@/stat/hooks'
import { LineChart } from './charts/LineChart'
import QuestionList from './charts/QuestionList'
import Select from 'react-select'

export function AssessmentOverviewPage() {
  const api = useApi()

  const [assessmentCount, setAssessmentCount] = React.useState([])
  const [daysReturned, setDaysReturned] = React.useState(7)
  const [assessmentId, setAssessmentId] = React.useState(null)
  const [courseId, setCourseId] = React.useState(null)
  const [courseList, setCourseList] = React.useState([])
  const [assessmentList, setAssessmentList] = React.useState([])
  const [selectedCourseName, setSelectedCourseName] = React.useState('')

  const [averageScore, setAverageScore] = React.useState([])

  React.useEffect(() => {
    async function fetchData() {
      try {
        const data = await api.fetchCourseList()
        setCourseList(data)
      } catch (error) {
        console.error('Error fetching course list:', error)
      }
    }
    fetchData()
  }, [])

  React.useEffect(() => {
    async function fetchData() {
      try {
        const data = await api.fetchAssessmentList({ course_number: courseId })
        setAssessmentList(data)
      } catch (error) {
        console.error('Error fetching assessment list:', error)
      }
    }
    if (courseId) fetchData()
  }, [courseId])

  React.useEffect(() => {
    async function fetchData() {
      try {
        const data = await api.fetchAssessmentData({
          course_number: courseId,
          days_returned: daysReturned,
          assessment_id: assessmentId,
        })
        setAssessmentCount(data)
      } catch (error) {
        console.error('Error fetching assessment counts:', error)
      }
      try {
        const data = await api.fetchGradeData({
          course_number: courseId,
          days_returned: daysReturned,
          assessment_id: assessmentId,
        })
        setAverageScore(data)
      } catch (error) {
        console.error('Error fetching assessment counts:', error)
      }
    }
    if (courseId && assessmentId) fetchData()
  }, [courseId, daysReturned, assessmentId])

  const labels = [courseId]

  const assessment_chart_options = {
    title: 'Assessment Taken \n',
    labels: labels,
  }

  let totalWeightedSum = 0
  let totalYSum = 0

  averageScore.forEach(item => {
    totalWeightedSum += item.x_axis * item.y_axis
    totalYSum += item.y_axis
  })
  const weightedAverage = (totalWeightedSum / totalYSum).toFixed(2)
  const grade_chart_options = {
    title:
      'Score Distribution Chart | Average score: ' +
      weightedAverage +
      '/' +
      averageScore[0]?.max_score.toFixed(2),
    labels: labels,
  }

  return (
    <div style={{ margin: '20px' }}>
      <h1 style={{ fontSize: '48px' }}>
        {courseId ? <>{selectedCourseName}</> : <></>}
      </h1>
      <h2 style={{ fontSize: '24px', color: 'gray', display: 'inline' }}>
        Assessment: {assessmentId}
      </h2>
      <Select
        isSearchable
        options={courseList}
        onChange={course => {
          setCourseId(course.value)
          setAssessmentId('')
          setSelectedCourseName(course.label)
        }}
        placeholder="Select a Course"
      />
      {courseId && assessmentList ? (
        <Select
          isSearchable
          options={assessmentList}
          onChange={assessment => setAssessmentId(assessment.value)}
          placeholder="Select an Assessment"
        />
      ) : (
        <></>
      )}
      {courseId && assessmentId ? (
        <div>
          <div style={{ float: 'right' }}>
            <p>
              <button onClick={() => setDaysReturned(0)}>All time</button> -{' '}
              <button onClick={() => setDaysReturned(90)}>90 days</button> -{' '}
              <button onClick={() => setDaysReturned(30)}>30 days</button> -{' '}
              <button onClick={() => setDaysReturned(7)}>7 Days</button>
            </p>
          </div>
          <div className="assessmentChartTop">
            {assessmentCount ? (
              <div className="lineChart">
                <LineChart
                  raw_data={assessmentCount}
                  chart_options={assessment_chart_options}
                />
              </div>
            ) : (
              <p>Loading assessment data...</p>
            )}
            {averageScore ? (
              <div className="lineChart">
                <LineChart
                  raw_data={averageScore}
                  chart_options={grade_chart_options}
                />
              </div>
            ) : (
              <p>Loading assessment data...</p>
            )}
          </div>
          <div>
            <QuestionList
              daysReturned={daysReturned}
              assessmentId={assessmentId}
              courseId={courseId}
            />
          </div>
        </div>
      ) : null}
    </div>
  )
}
