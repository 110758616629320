import React from 'react'

/**
 * Contains the main component for a custom element app,
 * bridging between the browser and React.
 *
 * Props:
 *     customElem     custom element instance
 *     AppComponent   main app component to render
 */
export class AppContainer extends React.Component {
  state = {
    attrs: this.props.customElem.getAttrs(),
  }

  /** Refreshes state.attrs to match the custom element attributes */
  refreshAttrs() {
    this.setState({
      attrs: this.props.customElem.getAttrs(), // triggers a render of AppComponent
    })
  }

  render() {
    return (
      <div id={this.props.customElem.appId} className={`me-app-storehouse`}>
        <this.props.AppComponent
          id={this.props.customElem.appId}
          config={this.props.customElem.appConfig} // config from DOM element constructor, if any
          attrs={this.state.attrs} // attrs from the DOM element, if any
        />
      </div>
    )
  }
}
