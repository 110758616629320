import React, { useState } from 'react'
import { useApi } from '@/stat/hooks'
import Loading from '../Loading'

const QuestionList = props => {
  const api = useApi()

  const { daysReturned, assessmentId, courseId } = props
  const [questionData, setQuestionData] = useState([])
  const [loading, setLoading] = useState(true)

  React.useEffect(() => {
    async function fetchData() {
      setLoading(true)
      try {
        const data = await api.fetchAssessmentQuestionData({
          course_number: courseId,
          days_returned: daysReturned,
          assessment_id: assessmentId,
        })
        setQuestionData(data)
        setLoading(false)
      } catch (error) {
        console.error('Error fetching assessment counts:', error)
      }
    }

    fetchData()
  }, [courseId, daysReturned, assessmentId])

  if (!questionData || loading) {
    return <Loading />
  }

  return (
    <div>
      <table style={{ width: '100%', textAlign: 'left' }}>
        <thead>
          <tr>
            <th style={{ fontSize: '24px' }}>Questions:</th>
            <th>Points</th>
            <th>Additional Question Data</th>
          </tr>
        </thead>
        <tbody>
          {questionData.map((question, index) => {
            const questionTypeMap = {
              TrueFalseResponse: 'True/False',
              FillinBlankResponse: 'Fill in the Blank',
              OpenEndedResponse: 'Open-Ended',
              MultipleChoiceResponse: 'Multiple Choice',
              MultipleResponseResponse: 'Multiple Response',
            }
            const extensions = JSON.parse(question.extensions)
            const questionTextWithHTML = extensions.questionText
            const parser = new DOMParser()
            const questionTextParsed = parser.parseFromString(
              questionTextWithHTML,
              'text/html',
            ).body.textContent
            const questionType =
              questionTypeMap[question?.question_type.replace(/['"]+/g, '')] ||
              'Unknown Question Type'

            let skippedPercentage = '0% Skipped'
            if (question.skipped_count) {
              skippedPercentage =
                (question.skipped_count / question.answer_count).toPrecision(
                  1,
                ) + '% Skipped'
            }

            // Calculate percentage
            const averagePercentage =
              (question?.average_score / question?.max_score) * 100

            // Determine the color based on conditions
            let averageColor = ''
            if (averagePercentage >= 90) {
              averageColor = 'green'
            } else if (averagePercentage < 75) {
              averageColor = 'red'
            }

            return (
              <tr key={index}>
                <td>
                  <strong>
                    <p>{questionTextParsed}</p>
                  </strong>
                  <br /> {questionType}
                </td>
                <td>
                  <strong>{question?.max_score}</strong>
                  <br /> Average{' '}
                  <span style={{ color: averageColor }}>
                    {question?.average_score}
                  </span>
                </td>
                <td>
                  {skippedPercentage} <br /> {question.answer_count} Times
                  answered
                </td>
              </tr>
            )
          })}
        </tbody>
      </table>
    </div>
  )
}

export default QuestionList
