import { defineCustomElement } from '@/lib/custom-element'
import './index.css'
import { PageViewWidget } from '@/stat/widgets/PageViewWidget'
import { TotalLaunchesWidget } from '@/stat/widgets/TotalLaunchesWidget'
import { RecentActivityWidget } from '@/stat/widgets/RecentActivityWidget'
import { AssessmentOverviewWidget } from '@/stat/widgets/AssessmentOverviewWidget'

defineCustomElement(
  'me-storehouse-student-page-views', // custom tag name
  ['userId', 'courseNumber'], // element attributes the app uses
  PageViewWidget, // main app component to render
)

defineCustomElement(
  'me-storehouse-total-launches', // custom tag name
  [], // element attributes the app uses
  TotalLaunchesWidget, // main app component to render
)

defineCustomElement(
  'me-storehouse-recent-activity', // custom tag name
  [], // element attributes the app uses
  RecentActivityWidget, // main app component to render
)

defineCustomElement(
  'me-storehouse-assessment-overview', // custom tag name
  [], // element attributes the app uses
  AssessmentOverviewWidget, // main app component to render
)
