import React from 'react'
import { useApi } from '@/stat/hooks'
import { ReactComponent as Rocket } from '@/stat/assets/rocket.svg'
import { ReactComponent as Highlight } from '@/stat/assets/tag.svg'
import { ReactComponent as Video } from '@/stat/assets/video.svg'
import { ReactComponent as Assessment } from '@/stat/assets/assessment.svg'

export function RecentActivity() {
  const api = useApi()
  const [recentActivity, setRecentActivity] = React.useState(null)

  React.useEffect(() => {
    async function fetchData() {
      const data = await api.fetchRecentActivity()
      setRecentActivity(data)
    }
    fetchData()
  }, [])

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1)
  }

  function getActivityComponent(activityType) {
    switch (activityType) {
      case 'ViewEvent':
        return Rocket
      case 'AnnotationEvent':
        return Highlight
      case 'AssessmentEvent':
        return Assessment
      case 'MediaEvent':
        return Video
      default:
        return Rocket
    }
  }

  function formatTimeElapsed(eventTime) {
    const date = new Date(eventTime)
    const now = Date.now()
    const timeDifference = now - date

    // Define the time units and their conversions in milliseconds
    const timeUnits = {
      year: 1000 * 60 * 60 * 24 * 365,
      month: 1000 * 60 * 60 * 24 * 30,
      week: 1000 * 60 * 60 * 24 * 7,
      day: 1000 * 60 * 60 * 24,
      hour: 1000 * 60 * 60,
      minute: 1000 * 60,
    }

    // Find the appropriate time unit to display
    for (const unit in timeUnits) {
      const unitValue = timeDifference / timeUnits[unit]
      if (unitValue >= 1) {
        // Handle months and minutes differently
        if (unit === 'month') {
          return `${Math.floor(unitValue)}mo`
        } else if (unit === 'minute') {
          return `${Math.floor(unitValue)}min`
        } else {
          // For other units, return the time difference without the "ago" or "in" part
          return `${Math.floor(unitValue)}${unit[0]}`
        }
      }
    }

    // If the difference is less than a minute
    return 'Just now'
  }

  return (
    <div className="box recent-box">
      {recentActivity?.map((activity, i) => {
        const ActivityComponent = getActivityComponent(activity?.type)
        if (!ActivityComponent) return null

        return (
          <div className="recent-list" key={i}>
            <ActivityComponent className="svg-icon" />
            <strong>{capitalizeFirstLetter(activity?.action)}:</strong>{' '}
            {activity?.description}{' '}
            <p className="how-long-ago">
              {formatTimeElapsed(activity?.eventTime)}
            </p>
          </div>
        )
      })}
    </div>
  )
}
