import React from 'react'
import { useApi } from '@/stat/hooks'

export function TotalLaunches() {
  const api = useApi()
  const [launches, setLaunches] = React.useState(null)

  React.useEffect(() => {
    async function fetchData() {
      const data = await api.fetchStudentTotalLaunches()
      setLaunches(data[0])
    }
    fetchData()
  }, [])

  const percentageDifference =
    (((launches?.login_count ?? 0) -
      (launches?.average_logins_for_course ?? 0)) /
      (launches?.average_logins_for_course ?? 1)) *
    100

  const averageTag =
    launches?.login_count === launches?.average_logins_for_course ? (
      <p className="grayed">About Average</p>
    ) : (
      <p
        className={
          launches?.login_count > launches?.average_logins_for_course
            ? 'above-average'
            : 'below-average'
        }
      >
        {launches?.login_count > launches?.average_logins_for_course
          ? '↗'
          : '↘'}{' '}
        {Math.abs(percentageDifference.toFixed(1))}%{' '}
        {launches?.login_count > launches?.average_logins_for_course
          ? 'above'
          : 'below'}{' '}
        class average of {launches?.average_logins_for_course.toFixed(1)}
      </p>
    )

  return (
    <div className="box summary-box centered">
      <p className="header-text">{launches?.login_count}</p>
      <p>Total Launches</p>
      {averageTag}
    </div>
  )
}
