import React from 'react'
import { App } from '@/stat/App'
import { StudentPageViews } from '@/stat/components/PageViews'

export function PageViewWidget() {
  return (
    <App>
      <StudentPageViews />
    </App>
  )
}
