import React from 'react'
import { App } from '@/stat/App'
import { RecentActivity } from '@/stat/components/RecentActivity'

export function RecentActivityWidget() {
  return (
    <App>
      <RecentActivity />
    </App>
  )
}
