import React from 'react'
import { useApi } from '@/stat/hooks'

export function StudentPageViews() {
  const api = useApi()

  const [pageViews, setPageViews] = React.useState(null)

  React.useEffect(() => {
    async function fetchData() {
      try {
        const data = await api.fetchStudentPageViews()
        setPageViews(data[0])
      } catch (error) {
        // Handle the error if the data fetching fails
        console.error('Error fetching page views:', error)
      }
    }

    fetchData()
  }, [])

  const percentageDifference =
    (((pageViews?.page_count ?? 0) -
      (pageViews?.average_page_views_for_course ?? 0)) /
      (pageViews?.average_page_views_for_course ?? 1)) *
    100

  const averageTag =
    pageViews?.page_count === pageViews?.average_page_views_for_course ? (
      <p className="grayed">About Average</p>
    ) : (
      <p
        className={
          pageViews?.page_count > pageViews?.average_page_views_for_course
            ? 'above-average'
            : 'below-average'
        }
      >
        {pageViews?.page_count > pageViews?.average_page_views_for_course
          ? '↗'
          : '↘'}{' '}
        {Math.abs(percentageDifference.toFixed(1))}%{' '}
        {pageViews?.page_count > pageViews?.average_page_views_for_course
          ? 'above'
          : 'below'}{' '}
        class average of {pageViews?.average_page_views_for_course.toFixed(1)}
      </p>
    )

  const callThingy = async () => {
    try {
      const data = await api.fetchStudentPageViews({
        headers: {
          Authorization: `Bearer IAMTHEMASTERCOMMANDER`,
        },
      })
      setPageViews(data[0])
    } catch (error) {
      // Handle the error if the data fetching fails
      console.error('Error fetching page views:', error)
    }
  }

  return (
    <div className="box summary-box centered">
      <p className="header-text">{pageViews?.page_count}</p>
      <p>Page Visits</p>
      {averageTag}
      <button onClick={callThingy}>Click Me</button>
    </div>
  )
}
