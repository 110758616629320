import React from 'react'
import { App } from '@/stat/App'
import { AssessmentOverviewPage } from '@/stat/components/AssessmentOverview'

export function AssessmentOverviewWidget() {
  return (
    <App>
      <AssessmentOverviewPage />
    </App>
  )
}
