import React from 'react'
import ReactDOMClient from 'react-dom/client'
import { nextInt } from '@/lib/struct'
import { AppContainer } from './container'

/**
 * Defines a custom element webcomponent in window.customElements
 * and bridges the webcomponent lifecycle and React lifecycle methods.
 */
export function defineCustomElement(tagName, attrNames, AppComponent) {
  if (!tagName) {
    throw new Error('tag name must be specified')
  }
  if (!Array.isArray(attrNames)) {
    throw new Error('attribute names must be given as an array, even if empty')
  }

  window.customElements.define(
    tagName,
    class extends HTMLElement {
      constructor(config) {
        super()
        this.appId = nextInt()
        this.appConfig = config || {}
        this.appRoot = null
        this.appRef = React.createRef()
      }

      /** Lifecycle callback when the element is added to the page */
      connectedCallback() {
        this.appRoot = ReactDOMClient.createRoot(this)
        this.appRoot.render(
          <AppContainer
            ref={this.appRef}
            customElem={this}
            AppComponent={AppComponent}
          />,
        )
      }

      /** Lifecycle callback when the element is removed from the page */
      disconnectedCallback() {
        this.appRoot && this.appRoot.unmount()
        this.appRoot = null
      }

      /** Lifecycle callback when an attribute value changes */
      attributeChangedCallback() {
        this.appRef.current?.refreshAttrs() // triggers the app to re-render
      }

      /** Returns the attribute changes we want to be notified of */
      static get observedAttributes() {
        return attrNames
      }

      /**
       * Returns a Map of attributes on the custom element.
       * Only the names in the attrNames array are included.
       */
      getAttrs() {
        const ret = new Map()
        const attrs = this.attributes
        for (const name of attrNames) {
          const attr = attrs.getNamedItem(name)
          ret.set(name, attr ? attr.value : '')
        }
        return ret
      }
    },
  )
}
