import React from 'react'
import { App } from '@/stat/App'
import { TotalLaunches } from '@/stat/components/TotalLaunches'
export function TotalLaunchesWidget() {
  return (
    <App>
      <TotalLaunches />
    </App>
  )
}
